import {
  PLAYLIST,
  SECTION_LABEL,
  SIMULATION_JOB_MONITORING,
  VIRTUAL_DEVELOPMENT_INFRASTRUCTURE,
  WORKSPACE_PAGE,
  YIELD_REPORT_PAGE,
} from './data-and-simulation-config-map';
import { GeneralApi, GeneralFeatureConfig } from './generalApi';
import { HdkConfigMap } from './hdk-config-map';


enum ServiceId {
  HDK = 'HDK',
  DATA_AND_SIMULATION = 'DATA_AND_SIMULATION',
}

export const ConfigMap = (
  options: GeneralFeatureConfig<GeneralApi>,
  serviceId: ServiceId,
) => {
  switch (serviceId) {
    case ServiceId.DATA_AND_SIMULATION: {
      const dataAndSimulationFeatures = {
        features: [
          SECTION_LABEL(options),
          WORKSPACE_PAGE(options),
          VIRTUAL_DEVELOPMENT_INFRASTRUCTURE(options),
          PLAYLIST(options),
          SIMULATION_JOB_MONITORING(options),
          YIELD_REPORT_PAGE(options),
        ],
      };

      const selectedFeatures = {
        features: [SECTION_LABEL(options)],
      };

      if (!options.features || options.features.length < 1)
        return dataAndSimulationFeatures;

      options.features.map((id) => {
        const element = featureMap[id];
       
        selectedFeatures.features.push(element(options));
      });
      return selectedFeatures;
    }
    case ServiceId.HDK: {
      return HdkConfigMap(options);
    }
  }
};

const featureMap: Partial<Record<string, any>> = {
  ['SECTION_LABEL']: SECTION_LABEL,
  ['WORKSPACE_PAGE']: WORKSPACE_PAGE,
  ['PLAYLIST']: PLAYLIST,
  ['VIRTUAL_DEVELOPMENT_INFRASTRUCTURE']: VIRTUAL_DEVELOPMENT_INFRASTRUCTURE,
  ['SIMULATION_JOB_MONITORING']: SIMULATION_JOB_MONITORING,
  ['YIELD_REPORT_PAGE']: YIELD_REPORT_PAGE,
};
