<h2 mat-dialog-title>
  {{ "Deployments.DeleteDeploymentDialog.Title" | translate }}
</h2>
<div mat-dialog-content>
  <p>{{ "Deployments.DeleteDeploymentDialog.Text" | translate }}</p>
  @if (showInfo) {
    <p>{{ "Deployments.DeleteDeploymentDialog.ForceText" | translate }}</p>
  }
  <div class="checkbox">
    <app-checkbox
      [text]="'Deployments.DeleteDeploymentDialog.ForceDelete' | translate"
      [tooltip]="'Deployments.DeleteDeploymentDialog.ForceText' | translate"
      (checked)="isSelected = $event"
      tooltipPosition="above"
      data-cy="force-delete-btn"
    ></app-checkbox>
  </div>
</div>
<div mat-dialog-actions [align]="'end'">
  <button mat-flat-button [mat-dialog-close]="true">
    {{ "General.Cancel" | translate }}
  </button>
  <button
    data-cy="dialog-confirm-button"
    class="dialog-confirm-btn"
    mat-flat-button
    (click)="deleteDeployment()"
    [mat-dialog-close]="true"
  >
    {{ "Deployments.DeleteDeploymentDialog.Confirm" | translate }}
  </button>
</div>
