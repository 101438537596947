<ng-container class="job-details-container" *ngIf="!loading; else loadingBar">

  <app-header-template *ngIf="jobDetails$ | async as jobDetails"
                       [itemName]="jobDetails.RunId"
                       [showBackButton]="true"
                       [useBrowserHistory]="true"
                       [isRowDisplayed]="true"
                       data-cy="project-details-overview"
  >
    <div
      [matTooltip]="'Jobs.Details.CancelTooltip' | translate"
      [matTooltipDisabled]="!shouldCancelButtonBeDisabled(jobDetails)">
      <button
        mat-flat-button
        [disabled]="shouldCancelButtonBeDisabled(jobDetails)"
        class="delete-button"
        data-cy="delete-job-button"
        (click)="openDeleteDialog()"
      >Cancel
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>
  </app-header-template>

  <app-details-expansion
    [title]="'Jobs.Details.Title' | translate"
    [description]="'Jobs.Details.Subtitle' | translate"
    [expanded]="false"
    [hideMenu]="true"
    data-cy="job-exec-details-expansion"
  >
    <ng-container *ngIf="jobDetails$ | async as jobDetails">
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.Status" | translate }} </span>
        <app-chip
          class="type-chip"
          [label]="jobDetails.Status"
          [variant]="jobDetails.Status | customVariantColorPipe"
        />
      </div>
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.Caller" | translate }} </span>
        <span class="value">{{ jobDetails.Caller }}</span>
      </div>
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.RunId" | translate }}</span>
        <span class="value">{{ jobDetails.RunId }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{ "Jobs.Details.JobCount" | translate }}</span>
        <span class="value">{{
          jobDetails.RunData.JobStates.JobCount
            ? jobDetails.RunData.JobStates.JobCount
            : "Not available"
          }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{ "Jobs.Details.ChildJobCount" | translate }}</span>
        <span class="value">{{
          jobDetails.RunData.JobStates.ChildJobCount
            ? jobDetails.RunData.JobStates.ChildJobCount
            : "Not available"
          }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{
          "Jobs.Details.FinishedChildTasks" | translate
          }}</span>
        <span class="value">{{
          jobDetails.RunData.JobStates.FinishedChildJobs
            ? jobDetails.RunData.JobStates.FinishedChildJobs
            : "Not available"
          }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{ "Jobs.Details.FailedJobs" | translate }}</span>
        <app-chip
          class="type-chip"
          [label]="jobDetails.RunData.JobStates.FailedJobs === undefined ? 'Not available' : jobDetails.RunData.JobStates.FailedJobs.toString()"
          [variant]="(jobDetails.RunData.JobStates.FailedJobs === undefined)
          ? ChipVariant.NEUTRAL_GRAY
          : jobDetails.RunData.JobStates.FailedJobs !== 0
          ? ChipVariant.ERROR
          : ChipVariant.SUCCESS"
        />
      </div>
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.InputBpl" | translate }}</span>
        <span class="value">{{ jobDetails.RunData.input_location }}</span>
      </div>
      <div class="job-details-fields" *ngIf="tab === CurrentTab.SIMULATION">
        <span class="key">{{ "Jobs.Details.RECIDS" | translate }}</span>
        <div class="value">
          <div class="recids-container">
            <!-- show only the first three RECIDS (the list can be very long)-->
            {{
            jobDetails.RunData.RECIDS.length === 0
              ? "Not available"
              : jobDetails.RunData.RECIDS.slice(0, 3).join(", ")
            }}
            {{ jobDetails.RunData.RECIDS.length > 3 ? "..." : "" }}
            <mat-icon
              *ngIf="jobDetails.RunData.RECIDS.length > 0"
              class="copy-icon"
              matTooltip="Copy"
              matTooltipPosition="right"
              (click)="copyToClipboard(jobDetails.RunData.RECIDS)"
            >file_copy
            </mat-icon>
          </div>
        </div>
      </div>
      <div class="job-details-fields">
        <span class="key">{{ "Jobs.Details.Costs" | translate }}</span>
        <span class="value"
        >{{
          jobDetails.Cost === undefined || jobDetails.Cost === ""
            ? "Not available"
            : (jobDetails.Cost | number : "1.2-2")
          }}
          $
        </span>
      </div>
    </ng-container>
  </app-details-expansion>

  <app-details-expansion
    [title]="'Jobs.WorkflowConfig.Title' | translate"
    [description]="'Jobs.WorkflowConfig.Subtitle' | translate"
    [expanded]="false"
    [hideMenu]="true"
    data-cy="job-workflow-config-expansion"
  >
    <ng-container *ngIf="jobDetails$ | async as jobDetails">
      <ng-container
        *ngFor="
          let item of Object.keys(jobDetails.RunData.workflow_configuration[firstElement])
        "
      >
        <div class="job-details-fields">
          <span class="key">{{ item }}</span>
          <ng-container *ngIf='item !== "custom_config"; else configField'>
                    <span class="value">{{
                      !item.toLowerCase().includes("date")
                        ? jobDetails.RunData.workflow_configuration[firstElement][item]
                        : (jobDetails.RunData.workflow_configuration[firstElement][item]
                          | customFormatDate)
                      }}</span>
          </ng-container>
          <ng-template #configField>
            <div class="value">
              <div class="config-container">
                {{
                jobDetails.RunData.workflow_configuration[firstElement][item]
                }}
                <mat-icon
                  class="file_download"
                  (click)="downloadConfig(jobDetails.RunData.workflow_configuration[firstElement][item])"
                >file_download_black_24dp
            </mat-icon>
              </div>
            </div>
          </ng-template>

        </div>
      </ng-container>
    </ng-container>
  </app-details-expansion>

  <div class="log-content" *ngIf="tab === CurrentTab.REPORTING">
    <div class="log-content__header">
      <span class="log-content__header__title" data-cy="jobs-title">{{ 'Jobs.LogFiles.Title' | translate }}</span>
      <span class="log-content__header__description">{{ 'Jobs.LogFiles.Subtitle' | translate }}</span>
    </div>
    <div class="log-content__action">
      <app-cae-button
        buttonText="{{ 'Jobs.Export' | translate }}"
        [color]="'secondary'"
        [icon]="'file_download_black_24dp'"
        (click)="downloadLog()">
      </app-cae-button>
    </div>
  </div>

  <app-child-jobs
    data-cy="child-jobs-table"
    *ngIf="tab === CurrentTab.SIMULATION"
    [runID]="(jobDetails$ | async)?.RunId"
  ></app-child-jobs>
</ng-container>

<ng-template #loadingBar>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
