import { cognitoConfig } from '../app/core/services/auth/cognitoConfig';
import { AwsStage } from '../app/core/models/enums/AwsStage';
import { AwsOrg } from '../app/core/models/enums/AwsOrg';
import { FeatureConfig } from './featureConfig';

const transformVersion = (version: string) => {
  return version.substring(0, 3);
};

const features: FeatureConfig[] = [
  FeatureConfig.workbencheRequest,
  FeatureConfig.downloadDevTools,
  FeatureConfig.partitionDropdown,
];

export const environment = {
  production: true,
  appVersion: transformVersion(require('../../package.json').version),
  AWS_STAGE: AwsStage.PROD,
  COGNITO_CONFIG: cognitoConfig[AwsOrg.PROD],
  enableGuard: true,
  features: features,
};
