import { Pipe, PipeTransform } from '@angular/core';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { DeviceColorStatus } from 'src/app/shared/stores/devices/models/deviceColorStatus';
import {
    isDeviceConnectedAndRunning,
    isDeviceConnectedAndSettingUp,
    isDeviceFailed,
    isDeviceHandlingActions,
    isDeviceInactive,
} from 'src/app/features/device-list/utils/helpers';

@Pipe({
    name: 'customDeviceColor',
    pure: false,
    standalone: true,
})
export class CustomDeviceColorPipe implements PipeTransform {
    transform(device: Device, deviceColorStatus: DeviceColorStatus): boolean {
        switch (deviceColorStatus) {
            case DeviceColorStatus.CONNECTED: {
                return isDeviceConnectedAndRunning(device);
            }
            case DeviceColorStatus.INACTION: {
                return isDeviceHandlingActions(device) || isDeviceConnectedAndSettingUp(device);
            }
            case DeviceColorStatus.INACTIVE: {
                return isDeviceInactive(device);
            }
            case DeviceColorStatus.FAILED: {
                return isDeviceFailed(device);
            }
        }
    }
}
