import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DisplayDetailComponent } from '../application-dashboard/components/application-details/display-detail.component';
import {
  DetailsExpansionComponent,
  MenuCommand,
  MenuItem,
} from '../../shared/components/details-expansion/details-expansion.component';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProjectDetailsService } from './services/project-details.service';
import { ProjectTableComponent } from './components/project-table/project-table.component';
import { ProjectDisplayDetailsComponent } from './components/project-display-details/project-display-details.component';
import { ProjectDetails } from './models/project-details';
import { Project } from '../../shared/stores/projects/models/project';
import { ProjectsStore } from '../../shared/stores/projects/projects.store';

@Component({
  selector: 'app-project-dashboard-details',
  standalone: true,
  imports: [
    CommonModule,
    DisplayDetailComponent,
    DetailsExpansionComponent,
    HeaderTemplateComponent,
    TranslateModule,
    MatProgressBarModule,
    ProjectTableComponent,
    ProjectDisplayDetailsComponent,
  ],
  templateUrl: './project-dashboard-details.component.html',
  styleUrls: ['./project-dashboard-details.component.scss'],
})
export class ProjectDashboardDetailsComponent {
  workbench = {
    Admin: 'Continental',
    Date: new Date().toString(),
  };
  menuItems: MenuItem[] = [
    {
      command: MenuCommand.EDIT,
      disabled: true,
      translationKey: 'General.Edit',
    },
  ];
 
  project$: Observable<Project | null> = this.activatedRoute.params.pipe(
    switchMap((params) => {
      return this.projectsStore.getProjectById(params['projectId']);
    }),
  );

  projectDetails$: Observable<ProjectDetails | null> =
    this.activatedRoute.params.pipe(
      switchMap((params) => {
        const projectId = params['projectId'];
        return this.projectDetailsService.getProjectDetailsById(projectId).pipe(
          catchError((err) => {
            console.error(err);
            return of(null);
          }),
        );
      }),
    );
    
  constructor(
    private activatedRoute: ActivatedRoute,
    private projectsStore: ProjectsStore,
    private projectDetailsService: ProjectDetailsService,
    
  ) {}
}
