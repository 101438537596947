import { AwsOrg } from '../../models/enums/AwsOrg';

export const cognitoConfig: Record<
  AwsOrg,
  {
    AWS_COGNITO_REGION: string;
    AWS_COGNITO_IDENTITY_PROVIDER: string;
    AWS_COGNITO_CLIENT_ID: string;
    AWS_COGNITO_AUTH_URL: string;
    AWS_COGNITO_USER_POOL_ID: string;
    BASE_DISPATCH_API_URL: string;
  }
> = {
  [AwsOrg.DEV]: {
    AWS_COGNITO_REGION: 'eu-west-1',
    AWS_COGNITO_IDENTITY_PROVIDER: 'CAEdgeKeycloakUserpool',
    AWS_COGNITO_CLIENT_ID: '60f9j1ckvcvjnv80s5va1oln42',
    AWS_COGNITO_AUTH_URL:
      'caedge-dispatch-dev.auth.eu-west-1.amazoncognito.com',
    AWS_COGNITO_USER_POOL_ID: 'eu-west-1_8sqhF0Ge0',
    BASE_DISPATCH_API_URL: 'https://api.portal.open-caedge.dev/api/',
  },
  [AwsOrg.QA]: {
    AWS_COGNITO_REGION: 'eu-west-1',
    AWS_COGNITO_IDENTITY_PROVIDER: 'CAEdgeKeycloakUserpool',
    AWS_COGNITO_CLIENT_ID: '5p120dmmprtk7ogckuvar2i95f',
    AWS_COGNITO_AUTH_URL: 'caedge-dispatch-qa.auth.eu-west-1.amazoncognito.com',
    AWS_COGNITO_USER_POOL_ID: 'eu-west-1_VdXnpRp5Y',
    BASE_DISPATCH_API_URL: 'https://api.portal.open-caedge.net/api/',
  },
  [AwsOrg.PROD]: {
    AWS_COGNITO_REGION: 'eu-west-1',
    AWS_COGNITO_IDENTITY_PROVIDER: 'CAEdgeKeycloakUserpool',
    AWS_COGNITO_CLIENT_ID: '5hl53g0hs2nbpi95d4ih0b8263',
    AWS_COGNITO_AUTH_URL:
      'caedge-dispatch-prod.auth.eu-west-1.amazoncognito.com',
    AWS_COGNITO_USER_POOL_ID: 'eu-west-1_qlG9jmccO',
    BASE_DISPATCH_API_URL: 'https://api.portal.open-caedge.com/api/',
  },
};
