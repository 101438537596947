import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { featureComponentMap } from 'src/app/core/services/config/utils/featureComponentMap';
import { Store } from '../../../core/models/classes/abstract.store';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { AppConfig } from './models/appConfig';
import { EnvConfigNode } from './models/appConfigInstanceType';
import { FeatureId } from './models/featureId';
import { NormalizedAppConfigItem } from './models/normalizedAppConfigItem';
import { RoutingStore } from './routing.store';

@Injectable({
  providedIn: 'root',
})
export class ConfigStore extends Store<AppConfig> {
  constructor(
    private router: Router,
    private routingStore: RoutingStore,
  ) {
    super({ projects: [] });
  }

  /**
   * Initializes the state and dynamically builds Angular
   * routes based on a project Id
   *
   * @param {AppConfig} config - [Raw config file structure]
   * @param {string} projectId - [project id from URL]
   * @param {string} applicationId - [application id from URL]
   */
  initState(config: AppConfig, projectId: string, applicationId: string) {
    this.setState(config);
    this.loadDynamicProjectRoutes(projectId, applicationId);
  }

  /**
   * Loads dynamic project routes and navigates to the
   * project dashboard for a given project id
   *
   * @param {string} projectId
   */
  navigateToProject(projectId: string) {
    this.loadDynamicProjectRoutes(projectId, '');
    setTimeout(() => {
      this.router.navigate(['/dashboard/project', projectId, 'overview']);
    }, 200);
  }

  /**
   * Initializes the dynamic routes for a project based
   * on the project and application id provided.
   * Also initializes the sidebar routing items.
   *
   * @param {string} projectId
   * @param {string} applicationId
   */
  loadDynamicProjectRoutes(projectId: string, applicationId: string) {
    const project = this.state.projects.find(
      (project) => project.id === projectId,
    );

    if (project && project.children) {
      const normalizedProjectConfig = project.children.map((feature) =>
        this.normalizeConfigTreeNode(feature),
      );
      this.initProjectRoutes(normalizedProjectConfig);
      this.routingStore.initSidebarRoutingItems(
        normalizedProjectConfig,
        projectId,
        applicationId,
      );
    }
  }

  /**
   * Recursive function tasked with normalization of the config
   * file structure into a uniform data structure.
   * It automatically assigns the appropriate component based on the
   * FeatureId provided. If no FeatureId matches, a not found component
   * is assigned.
   *
   * @param {any} props - [Raw ProjectConfig]
   * @param {string} [parentPath] - [parentPath used to calculate the fullPath of a config item]
   * @returns {NormalizedAppConfigItem} [Uniform data structure used to build the routes and sidebar items]
   */
  normalizeConfigTreeNode = (
    props: any,
    parentPath?: string,
  ): NormalizedAppConfigItem => {
    const path = parentPath ? parentPath + '/' + props.path : props.path;
    const featureId = props.featureId as FeatureId;
    const featureComponent = featureComponentMap[featureId];
    const component = featureComponent || NotFoundComponent;

    const baseNavItemProps: NormalizedAppConfigItem = {
      id: props.id,
      ...(props.featureId && { featureId: props.featureId }),
      label: props.label,
      path: props.path,
      isNested: !!props.isNested,
      type: props.type,
      ...(props.API && { API: props.API }),
      extends: {
        nonNavigatable: !!props.nonNavigatable,
        component,
        ...(props.icon && { icon: props.icon }),
        fullPath: path,
        currentPath: props.path,
      },
    };

    if (props.children && props.children?.length > 0) {
      baseNavItemProps.children = props.children.map((item: EnvConfigNode) =>
        this.normalizeConfigTreeNode(item, path),
      );
    }
    return baseNavItemProps;
  };

  /**
   * Builds Angular Routes from the config file and resets the
   * router config with them
   *
   * @param {NormalizedAppConfigItem[]} config - [List of normalized config items]
   */
  initProjectRoutes(config: NormalizedAppConfigItem[]) {
    const nestedRoutes = this.getNestedRoutes(config);
    const flattenedRoutes: Route[] = this.flattenRoutes(nestedRoutes);
    this.resetRouterConfig(flattenedRoutes);
  }

  /**
   * Creates nested Angular routes from the normalized config items.
   * Assigns corresponding ApiRecords to the 'data' object of a route
   * where it can be read by the ActivatedRoute service.
   *
   * @param {NormalizedAppConfigItem[]} appConfig
   * @returns {Route[]} [Angular nested routes array]
   */
  getNestedRoutes = (appConfig: NormalizedAppConfigItem[]): Route[] => {
    return appConfig.map((item) => {
      const {
        children,
        extends: { component, fullPath },
        API,
      } = item;

      return {
        path: fullPath,
        component,
        ...(children &&
          children.length > 0 && {
            children: this.getNestedRoutes(children),
          }),
        ...(API && { data: { API } }),
      };
    });
  };

  /**
   * Flattens a given nested Angular Route list into a single list
   * so it can be correctly interpreted by the Angular Router.
   *
   * @param {Route[]} routes - [Nested Routes List]
   * @returns {Route[]} [Flattened Routes List]
   */
  flattenRoutes = (routes: Route[]): Route[] => {
    return routes.flatMap((route) => {
      const newRoute = cloneDeep(route);
      if (newRoute.children) {
        const childrenRoutes = newRoute.children;
        delete newRoute.children;
        return [newRoute, ...this.flattenRoutes(childrenRoutes)];
      }
      return newRoute;
    });
  };

  /**
   * Resets the Angular Router config to the original state,
   * without any dynamic project routes.
   * Is used when navigating to a page without dynamic routes
   * or when logging out
   *
   */
  clearDynamicProjectRoutes() {
    this.resetRouterConfig([]);
  }

  /**
   * Wrapper over the Angular router.resetConfig method.
   * It injects a given flat Angular routes list into the
   * children of the 'dashboard' route (the protected content
   * of the application lies there).
   *
   * @param {Route[]} routes - [Flat Angular Routes list]
   */
  resetRouterConfig(routes: Route[]) {
    const newRouterConfig: Route[] = this.router.config.map((route) => {
      if (route.path === 'dashboard') {
        const originalChildren = route.children!.filter((route) =>
          ['home', 'users', '', '**'].includes(route.path!),
        );
        const updatedChildren = [...routes, ...originalChildren];
        return {
          ...route,
          children: updatedChildren,
        };
      }
      return route;
    });
    this.router.resetConfig(newRouterConfig);
  }
}
