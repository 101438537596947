import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceListItemComponent } from '../device-list-item/device-list-item.component';
import { EmptySectionComponent } from 'src/app/shared/components/empty-section/empty-section.component';
import { TranslateModule } from '@ngx-translate/core';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { deviceIsInactive } from '../../utils/helpers';
import { DeviceInstanceStatus } from 'src/app/shared/stores/devices/models/deviceInstanceStatus';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { DeviceInstanceState } from 'src/app/shared/stores/devices/models/deviceInstanceState';
import { EmptySectionIcon } from 'src/app/shared/components/empty-section/models/empty-section';
import { CustomDeviceColorPipe } from 'src/app/core/pipes/custom-device-color.pipe';
import { DeviceColorStatus } from 'src/app/shared/stores/devices/models/deviceColorStatus';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';
import { MatIcon } from '@angular/material/icon';
import { SelectedDeviceService } from '../../services/selected-device/selected-device.service';

@Component({
  selector: 'app-device-list-content',
  standalone: true,
  imports: [
    CommonModule,
    DeviceListItemComponent,
    EmptySectionComponent,
    TranslateModule,
    CustomDeviceColorPipe,
    MatIcon,
  ],
  templateUrl: './device-list-content.component.html',
  styleUrls: ['./device-list-content.component.scss'],
})
export class DeviceListContentComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() devices: Device[] = [];

  DeviceInstanceStatus = DeviceInstanceStatus;
  DeviceConnectionStatus = DeviceConnectionStatus;
  DeviceInstanceState = DeviceInstanceState;
  DeviceColorStatus = DeviceColorStatus;
  EmptySectionIcon = EmptySectionIcon;
  EmptySectionScope = EmptySectionScope;

  constructor(private selectedDeviceService: SelectedDeviceService) {}

  ngOnInit() {
    this.selectedDeviceService.clearSelectedDevices();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['devices']) {
      this.selectedDeviceService.loadSelectedDevices(this.devices);
    }
  }

  isInactive(device: Device) {
    return deviceIsInactive(device);
  }

  isSelected(device: Device): boolean {
    return this.selectedDeviceService.isSelected(device);
  }

  selectDevice(device: Device) {
    this.selectedDeviceService.selectDevice(device);
  }

  ngOnDestroy() {
    this.selectedDeviceService.clearSelectedDevices();
    this.selectedDeviceService.clearDeleteDeviceResponse();
  }
}
