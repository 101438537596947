import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProjectsByTenant } from '../../../../shared/stores/projects/models/project';
import { TenantsByUser } from '../../../../shared/stores/projects/models/tenants';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectsApiService {
  constructor(private http: HttpClient) {}

  getTenantsFromJWT(): Observable<TenantsByUser> {
    const configUrl =
      environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL +
      'tenants/from_jwt_token';
    return this.http.get(configUrl) as Observable<TenantsByUser>;
  }

  getProjects(tenant_id: string) {
    // For PG Tenant only the projects from the user should be shown
    const allProjects = `tenants/${tenant_id}/projects`;
    const playGroundProjects = 'projects/my_projects';
    const configUrl =
      environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL +
      (tenant_id === 'T010' ? playGroundProjects : allProjects);
    return this.http.get(configUrl) as Observable<ProjectsByTenant>;
  }
}
