@if (isLoading) {
  @if (loadingType === LoadingTypes.BAR) {
    <div class="container">
      <p>&lt;CAEdge&gt;ing some data for you...</p>
      <mat-progress-bar mode="indeterminate" role="progressbar" aria-label="Application Data Retrieval Progress Bar"></mat-progress-bar>
    </div>
  }
  @if (loadingType === LoadingTypes.SPINNER) {
    <div class="container">
      <mat-progress-spinner mode="indeterminate" [diameter]="spinnerDiameter"></mat-progress-spinner>
    </div>
  }
} @else if (isError) {
  <app-empty-section
    class="empty-container"
    data-cy="application-list-forbidden"
    [iconName]="EmptySectionIcon.SPORTS_BAR"
    [emptySectionScope]="emptySectionScope"
  >
    <div class="container">
      @if (errorMessage) {
        <p class="error-text">{{ errorMessage || "Http.Errors.Unknown" | translate }}</p>
      } @else {
        <p class="error-text">{{ "Http.Errors.General1" | translate }} ({{ errorStatusCode }})</p>
        <p class="error-text">{{ "Http.Errors.General2" | translate }}</p>
        <p class="error-text">{{ "Http.Errors.General3" | translate }}</p>
      }
    </div>
  </app-empty-section>
} @else {
  <ng-content></ng-content>
}
