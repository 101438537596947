import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { DefaultFilters } from 'src/app/core/services/default/defaultFilters';
import { Injectable } from '@angular/core';
import { Option } from 'src/app/core/models/interfaces/option';
import {
  DEVICE_SORT_KEYS,
  DEVICE_TYPE_KEYS,
  DeviceStatusKey,
  DeviceSortKey,
  DeviceTypeKey,
  filterKeyToDevicePropertyMap,
  DeviceCreatorKey,
  DEVICE_CREATOR_KEYS,
  DEVICE_STATUS_KEYS,
} from '../../utils/selectionKeys';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { AuthStore } from 'src/app/shared/stores/auth/auth.store';
import { getDeviceChipLabel } from '../../utils/helpers';

type DeviceListSelectionData = {
  deviceType: Option<DeviceTypeKey>;
  deviceStatus: Option<DeviceStatusKey>;
  sortBy: Option<DeviceSortKey>;
  deviceCreator: Option<DeviceCreatorKey>;
};

@Injectable({
  providedIn: 'root',
})
export class DeviceListService implements DefaultFilters {
  constructor(
    private translate: TranslateService,
    private authStore: AuthStore,
  ) { }

  defaultFilterValues(): DeviceListSelectionData {
    return {
      deviceType: {
        id: DEVICE_TYPE_KEYS.ALL,
        label: this.translate.instant(DEVICE_TYPE_KEYS.ALL),
      },
      deviceStatus: {
        id: DEVICE_STATUS_KEYS.ALL,
        label: this.translate.instant(DEVICE_STATUS_KEYS.ALL),
      },
      sortBy: {
        id: DEVICE_SORT_KEYS.ALPHABETICAL,
        label: this.translate.instant(DEVICE_SORT_KEYS.ALPHABETICAL),
      },
      deviceCreator: {
        id: DEVICE_CREATOR_KEYS.ALL,
        label: this.translate.instant(DEVICE_CREATOR_KEYS.ALL),
      },
    };
  }
  deviceMap = ([
    devices,
    searchFilter,
    typeFilter,
    statusFilter,
    sortByOption,
    createdByOption,
  ]: [
      Device[],
      string,
      Option<DeviceTypeKey>,
      Option<DeviceStatusKey>,
      Option<DeviceSortKey>,
      Option<DeviceCreatorKey>,
    ]) => {
    return devices
      .filter((device) => {
        const deviceSearchFilterCheck: boolean = device.deviceId
          .toLowerCase()
          .includes(searchFilter.toLowerCase());
        const deviceTypeFilterCheck: boolean =
          typeFilter.id === DEVICE_TYPE_KEYS.ALL ||
          device.instanceType === filterKeyToDevicePropertyMap[typeFilter.id];
        const deviceStatusFilterCheck: boolean =
          statusFilter.id === DEVICE_STATUS_KEYS.ALL ||
          getDeviceChipLabel(device) === statusFilter.id;
        const deviceCreatorFilterCheck: boolean =
          createdByOption.id === DEVICE_CREATOR_KEYS.ALL ||
          device.createdBy === this.authStore.userId;
        return (
          deviceSearchFilterCheck &&
          deviceTypeFilterCheck &&
          deviceStatusFilterCheck &&
          deviceCreatorFilterCheck
        );
      })
      .sort(this.deviceSort(sortByOption.id));
  };

  deviceSort = (sortBy: DeviceSortKey): ((a: Device, b: Device) => number) => {
    switch (sortBy) {
      case 'SortKeys.Alphabetical':
        return (a: Device, b: Device) =>
          a.deviceId.localeCompare(b.deviceId, 'en');

      case 'SortKeys.Date':
        return (a: Device, b: Device) => {
          const aDate = new Date(a.creationDate).getTime();
          const bDate = new Date(b.creationDate).getTime();
          return bDate - aDate;
        };

      case 'DeviceList.FilterKeys.DateOptions.LastUseDate':
        return (a: Device, b: Device) => {
          const distantPast = 86400000;
          const aDate = a.lastUsedDate
            ? new Date(a.lastUsedDate).getTime()
            : distantPast;
          const bDate = b.lastUsedDate
            ? new Date(b.lastUsedDate).getTime()
            : distantPast;
          return bDate - aDate;
        };

      case 'DeviceList.FilterKeys.DateOptions.DeletionScheduleDate':
        return (a: Device, b: Device) => {
          const distantPast = 86400000;
          const aDate = a.scheduledDeletionDate
            ? new Date(a.scheduledDeletionDate).getTime()
            : distantPast;
          const bDate = b.scheduledDeletionDate
            ? new Date(b.scheduledDeletionDate).getTime()
            : distantPast;
          return aDate - bDate;
        };
    }
  };

  getOptions<T extends string>(
    keys: readonly T[],
    optionKey: keyof DeviceListSelectionData,
    setSelectedOption: (option: Option<T>) => void,
  ): Observable<Option<T>[]> {
    return this.translate.stream([...keys]).pipe(
      map((translations: Record<T, string>) =>
        Object.entries(translations as Record<string, string>).map(
          ([sortKey, translation]: [string, string]): Option<T> => ({
            id: sortKey as T,
            label: translation,
          }),
        ),
      ),
      tap((options) => {
        const selectedOption = this.selectMenusSource.value[optionKey];
        setSelectedOption(
          options.find((option) => option.id === selectedOption.id)!,
        );
      }),
    );
  }

  get DeviceCreatorOptions$(): Observable<Option<DeviceCreatorKey>[]> {
    return this.getOptions(
      [DEVICE_CREATOR_KEYS.ALL, DEVICE_CREATOR_KEYS.ME],
      'deviceCreator',
      this.selectedDeviceCreator,
    );
  }

  get deviceTypeOptions$(): Observable<Option<DeviceTypeKey>[]> {
    return this.getOptions(
      [DEVICE_TYPE_KEYS.ALL, DEVICE_TYPE_KEYS.REAL, DEVICE_TYPE_KEYS.VHPC],
      'deviceType',
      this.selectedDeviceType,
    );
  }

  get deviceConnectionStatusOptions$(): Observable<Option<DeviceStatusKey>[]> {
    return this.getOptions(
      [
        DEVICE_STATUS_KEYS.ALL,
        DEVICE_STATUS_KEYS.CONNECTED,
        DEVICE_STATUS_KEYS.DISCONNECTED,
        DEVICE_STATUS_KEYS.CREATING,
        DEVICE_STATUS_KEYS.DELETING,
        DEVICE_STATUS_KEYS.CONNECTING,
        DEVICE_STATUS_KEYS.FAILED,
        DEVICE_STATUS_KEYS.STARTING,
        DEVICE_STATUS_KEYS.STOPPING,
        DEVICE_STATUS_KEYS.UNKNOWN_DEVICE,
      ],
      'deviceStatus',
      this.setSelectedDeviceConnectionStatus,
    );
  }

  get sortByOptions$(): Observable<Option<DeviceSortKey>[]> {
    return this.getOptions(
      [
        DEVICE_SORT_KEYS.ALPHABETICAL,
        DEVICE_SORT_KEYS.DATE,
        DEVICE_SORT_KEYS.LAST_USED_DATE,
        DEVICE_SORT_KEYS.SCHEDULED_DELETION_DATE,
      ],
      'sortBy',
      this.setSelectedSortBy,
    );
  }

  private selectMenusSource: BehaviorSubject<DeviceListSelectionData> =
    new BehaviorSubject<DeviceListSelectionData>(this.defaultFilterValues());

  get selectedDeviceCreator$(): Observable<Option<DeviceCreatorKey>> {
    return this.selectMenusSource.pipe(map((value) => value.deviceCreator));
  }

  selectedDeviceCreator = (deviceCreator: Option<DeviceCreatorKey>) => {
    this.selectMenusSource.next({
      ...this.selectMenusSource.value,
      deviceCreator,
    });
  };

  get selectedDeviceType$(): Observable<Option<DeviceTypeKey>> {
    return this.selectMenusSource.pipe(map((value) => value.deviceType));
  }

  selectedDeviceType = (deviceType: Option<DeviceTypeKey>) => {
    this.selectMenusSource.next({
      ...this.selectMenusSource.value,
      deviceType,
    });
  };

  get selectedDeviceConnectionStatus$(): Observable<Option<DeviceStatusKey>> {
    return this.selectMenusSource.pipe(map((value) => value.deviceStatus));
  }
  setSelectedDeviceConnectionStatus = (
    deviceStatus: Option<DeviceStatusKey>,
  ) => {
    this.selectMenusSource.next({
      ...this.selectMenusSource.value,
      deviceStatus,
    });
  };

  get selectedSortBy$(): Observable<Option<DeviceSortKey>> {
    return this.selectMenusSource.pipe(map((value) => value.sortBy));
  }
  setSelectedSortBy = (sortBy: Option<DeviceSortKey>) => {
    this.selectMenusSource.next({ ...this.selectMenusSource.value, sortBy });
  };

  checkActiveFilters() {
    const currentState = this.selectMenusSource.getValue();
    const defaultState = this.defaultFilterValues();
    return (
      currentState.deviceType.id !== defaultState.deviceType.id ||
      currentState.deviceStatus.id !== defaultState.deviceStatus.id ||
      currentState.sortBy.id !== defaultState.sortBy.id ||
      currentState.deviceCreator.id !== defaultState.deviceCreator.id
    );
  }

  clearActiveFilters() {
    this.selectMenusSource.next(this.defaultFilterValues());
  }
}
