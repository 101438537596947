import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, Observable, of, tap } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { ProjectUser } from 'src/app/shared/components/user-list-components/models/user';
import { ProjectUsersStore } from 'src/app/shared/stores/project-users/projectUsers.store';
import { ProjectUserResponse } from 'src/app/shared/stores/project-users/models/project-user';
import { Method } from 'src/app/shared/stores/config/models/method';
import { environment } from 'src/environments/environment';
import { generatePath } from 'src/app/shared/utils/generatePath';
import { RemoveUser } from '../model/remove-user';
import { UsersPayload } from '../model/add-users-payload';

@Injectable({
  providedIn: 'root',
})
export class ProjectUsersApiService {
  private readonly BASE_URL = environment.COGNITO_CONFIG.BASE_DISPATCH_API_URL;

  constructor(
    private apiService: ApiService,
    private projectUsersStore: ProjectUsersStore,
  ) {}

  getProjectUsers(projectId: string): Observable<ProjectUser[]> {
    const getProjectUsersUrl: string = `${this.BASE_URL}projects/:projectId/users`;
    const apiRecord: ApiRecord = {
      url: generatePath(getProjectUsersUrl, { projectId }),
      method: Method.GET,
    };
    return this.apiService.request<ProjectUserResponse>({ apiRecord }).pipe(
      tap((projectUsers) => {
        this.projectUsersStore.setState({
          projectUsers: projectUsers.Users,
          isLoading: false,
          hasError: false,
        });
      }),
      catchError((err) => {
        this.projectUsersStore.setState({
          projectUsers: [],
          isLoading: false,
          hasError: true,
        });
        return of(err);
      }),
    );
  }

  removeUser(projectId: string, userId: string): Promise<RemoveUser> {
    const apiRecord: ApiRecord = {
      url: generatePath(
        `${this.BASE_URL}projects/:projectId/users/:userId/roles`,
        {
          projectId,
          userId,
        },
      ),
      method: Method.DELETE,
    };
    const request = this.apiService.request<RemoveUser>({ apiRecord });
    return lastValueFrom(request);
  }
  
  addUsersToProject(
    projectId: string,
    role: string,
    users: string[]
  ): Promise<any> {
    const addUsersToProjectUrl: string = `${this.BASE_URL}projects/:projectId/users/roles/:role`;
    const apiRecord: ApiRecord = {
      url: generatePath(addUsersToProjectUrl, { projectId, role}),
      method: Method.POST,
    };
    const usersPayload: UsersPayload = {
      Users: users
    };
    const request = this.apiService.request<any>({ apiRecord, body: usersPayload });
    return lastValueFrom(request);
  }
}
