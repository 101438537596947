<div class="details-container">
  <ng-container *ngIf="entries" >
    <div>
      <ng-container [ngTemplateOutlet]="displayEntries"
                    [ngTemplateOutletContext]="{ $implicit: entries, truncate: true}">
      </ng-container>
    </div>
    @if(entriesSecondColumn !== undefined) {
      <div>
        <ng-container [ngTemplateOutlet]="displayEntries"
                      [ngTemplateOutletContext]="{ $implicit: entriesSecondColumn, truncate: false}">
        </ng-container>
      </div>
    }
  </ng-container>

  <ng-template #displayEntries let-items let-truncate="truncate">
    @for (item of items; track item) {
      <div class="fields error-fields" data-cy="device-details-information" *ngIf="item.value">
        <span class="key"  data-cy="display-details-key">{{ translationKey + "." + item.key | translate }}</span>
        <span class="value" data-cy="display-details-value">{{
          !item.key.toLowerCase().includes("date")
          ? (truncate? (item.value | truncate: 25) : item.value)
          : (item.value | date: dateFormat : 'UTC')
          }}
        </span>
        <mat-icon class="copy-icon" *ngIf="displayCopyIcon" (click)="copyToClipboard(item.value)">file_copy</mat-icon>
      </div>
    }
  </ng-template>
</div>
