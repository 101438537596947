import { Component, Input, OnInit } from '@angular/core';
import { Device } from '../../../../../shared/stores/devices/models/device/device';
import {
  DeploymentStatus,
  DeploymentStatusMap,
} from '../../../../../shared/stores/deployment/models/deployment';
import {
  ChipComponent,
  ChipVariant,
} from '../../../../../shared/components/chip/chip.component';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DeployedApplication } from '../../../models/deviceApplications';
import { ConvertToTypeLabelsPipe } from '../../../../application-dashboard/pipes/convert-to-type-labels.pipe';
import { MatExpansionPanelTitle } from '@angular/material/expansion';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ContentWrapperComponent } from '../../../../../shared/components/content-wrapper/content-wrapper.component';
import { FeatureComponent } from '../../../../../core/models/classes/feature.component';
import { Observable, of } from 'rxjs';
import { Application } from '../../../../../shared/stores/deployment/models/application';
import { ApplicationListApiService } from '../../../../project-dashboard/components/application-list/services/application-list-api.service';
import { ActivatedRoute, Router } from '@angular/router';

export type DeviceDetailsDeploymentsAPI = 'getAppVersions' | 'getAppDetails';

@Component({
  selector: 'app-device-deployment-detail',
  standalone: true,
  imports: [
    ChipComponent,
    AsyncPipe,
    ContentWrapperComponent,
    CommonModule,
    MatIcon,
    MatTooltip,
    TranslateModule,
    ConvertToTypeLabelsPipe,
    MatExpansionPanelTitle,
  ],
  templateUrl: './device-deployment-detail.component.html',
  styleUrl: './device-deployment-detail.component.scss',
})
export class DeviceDeploymentDetailComponent
  extends FeatureComponent<DeviceDetailsDeploymentsAPI>
  implements OnInit
{
  @Input() device: Device = {} as Device;
  @Input({ required: true }) deployment: DeployedApplication =
    {} as DeployedApplication;
  readonly DeploymentStatus = DeploymentStatus;
  deploymentStatusLabel = '';
  deploymentStatusVariant = ChipVariant.SECONDARY;

  protected readonly DeviceConnectionStatus = DeviceConnectionStatus;
  protected readonly ChipVariant = ChipVariant;
  readonly DeploymentStatusMap = DeploymentStatusMap;
  application$: Observable<Application> = of({} as Application);

  private variantMap: { [key: string]: ChipVariant } = {
    failed: ChipVariant.ERROR,
    success: ChipVariant.SUCCESS,
    default: ChipVariant.SECONDARY,
  };

  constructor(
    private appService: ApplicationListApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initData();
  }

  private initData() {
    if (!this.API) return;
    this.deploymentStatusLabel =
      DeploymentStatusMap[this.deployment.deploymentState];
    this.deploymentStatusVariant =
      this.variantMap[this.deploymentStatusLabel] || this.variantMap['default'];
    this.application$ = this.appService.getAppDetails(
      this.API.getAppDetails,
      this.deployment.appId,
    );
  }

  openApplicationPage() {
    this.router.navigate([`../../application/${this.deployment.appId}`], {
      relativeTo: this.activatedRoute,
    });
  }
}
