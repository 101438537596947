import {
  Component,
  Input,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { User } from '../../../../shared/components/user-list-components/models/user';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { UserRoles } from '../../model/user-roles';
import { MatOption, MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogContentService } from 'src/app/shared/components/dialog/services/dialog-content.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';

@Component({
  selector: 'app-add-user-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    MatChipsModule,
    MatIconModule,
  ],
  templateUrl: './add-user-dialog.component.html',
  styleUrl: './add-user-dialog.component.scss',
})
export class AddUserDialogComponent implements OnDestroy, OnChanges {
  @Input({ required: true }) tenantUserList: User[] = [];
  @Input({ required: true }) usersAlreadyAdded: User[] = [];

  private destroy$ = new Subject<void>();

  selectUserEmail = new FormControl('', [Validators.email]);
  selectUserRole = new FormControl(UserRoles.readonly, Validators.required);
  roles = [UserRoles.readonly, UserRoles.operations];
  selectedEmails: string[] = [];
  defaultSelectedRole: UserRoles = UserRoles.readonly;

  filteredOptions: Observable<User[]> | undefined;
  constructor(
    private dialogContentService: DialogContentService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) {}

  ngOnChanges(): void {
    this.filteredOptions = this.selectUserEmail.valueChanges.pipe(
      startWith(''),
      map((value) => this.emailFilter(value || '')),
      takeUntil(this.destroy$),
    );
  }

  emailFilter(filterValue: string): User[] {
    const searchTerm = filterValue.toLowerCase();
    return this.tenantUserList.filter((user) => {
      const userData = [
        user.email,
        user.firstName,
        user.lastName,
        `${user.firstName} ${user.lastName}`,
      ]
        .map((s) => s.toLowerCase())
        .join(' ');

      return userData.includes(searchTerm);
    });
  }

  autocompleteOptionSelected(selectedOption: MatOption): void {
    const emailsOfAlreadyAddedUsers: string[] = this.usersAlreadyAdded.map((user) => user.email);
    const selectedEmail: string = selectedOption.value;
    if (emailsOfAlreadyAddedUsers.includes(selectedEmail)) {
      this.snackbarService.notifyError(this.translate.instant('UserList.AddUser.UserAlreadyInTheProject'));
    } else {
      if (this.selectedEmails.includes(selectedEmail)) {
        this.snackbarService.notifyError(this.translate.instant('UserList.AddUser.UserAlreadyInTheList'));
      } else {
        this.selectedEmails.push(selectedEmail);
        this.dialogContentService.makeDialogContentValid(true);
      }
    }
    selectedOption.deselect();
    this.selectUserEmail.setValue('');
  }

  removeEmailFromList(selectedEmail: string): void {
    this.selectedEmails = this.selectedEmails.filter(
      (email) => email !== selectedEmail,
    );
    if (this.selectedEmails.length === 0) {
      this.dialogContentService.makeDialogContentValid(false);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
