@if (devices.length === 0) {
<app-empty-section [iconName]="EmptySectionIcon.COFFEE_MAKER" [emptySectionScope]="EmptySectionScope.PAGE">
  {{ "DeviceList.NoDevices" | translate }}
</app-empty-section>
} @else {
  <div data-cy="device-list-container" class="device-list-container">
    @for (device of devices; track device.deviceId) {
      <app-device-list-item
        [ngClass]="{
          'connected-device':
            device | customDeviceColor: DeviceColorStatus.CONNECTED,
          'in-action-device':
            device | customDeviceColor: DeviceColorStatus.INACTION,
          'inactive-device':
            device | customDeviceColor: DeviceColorStatus.INACTIVE,
          'failed-device': device | customDeviceColor: DeviceColorStatus.FAILED,
          'selected-device': isSelected(device)
        }"
        [device]="device"
        [isInactive]="isInactive(device)"
        class="device-list-item"
        data-cy="device-list-item"
      >
      </app-device-list-item>
    }
  </div>
}
