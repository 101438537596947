export enum DeviceConnectionStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  UNKNOWN_DEVICE = 'unknown device',
}

export enum DeviceStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  UNKNOWN_DEVICE = 'UNKNOWN_DEVICE',
  DELETING = 'Deleting',
  FAILED = 'Failed',
  CREATING = 'Creating',
  STOPPING = '.Stopping',
  STARTING = 'Starting',
}
