<div class="project-user-container" data-cy="user-list-content">
  <div class="project-user-data-container">
    <p class="project-user-name">{{ fullName() }}</p>
    <p class="project-user-email">{{ user.email }}</p>
  </div>
  <div class="project-user-roles">
    @for (serviceRole of serviceRoles; track $index) {
      <app-chip
        data-cy="user-service-role-chip"
        label="{{serviceRole}}"
        [variant]="ChipVariant.INFO"
      />
    }
  </div>
  <button class="popup-menu" mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
</div>
<mat-menu class="caedge-menu" #menu="matMenu">
  <button
    class="caedge-menu-item"
    mat-menu-item
    [disabled]="true"
    (click)="removeUserFromProject.emit(user)"
  >
    {{ "Project.ProjectUsers.DeleteUser" | translate }}
  </button>
</mat-menu>
