import {
  Component,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunctionBarComponent } from 'src/app/shared/components/function-bar/function-bar.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { ProjectUsersStore } from 'src/app/shared/stores/project-users/projectUsers.store';
import {
  combineLatest,
  debounceTime,
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { ProjectUsersState } from 'src/app/shared/stores/project-users/models/project-users-state';
import { ProjectUsersApiService } from 'src/app/features/project-users/services/project-users-api.service';
import { ProjectUsersService } from 'src/app/features/project-users/services/project-users.service';
import {
  ListGridToggleComponent,
  View,
} from 'src/app/shared/components/list-grid-toggle/list-grid-toggle.component';
import { SelectMenuComponent } from 'src/app/shared/components/select-menu/select-menu.component';
import { Option } from 'src/app/core/models/interfaces/option';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  AlphabeticalOrderId,
  AlphabeticalOrderLabel,
} from 'src/app/core/models/enums/alphabeticalOrder';
import { UsersListComponent } from 'src/app/shared/components/user-list-components/users-list/users-list.component';
import { UserListService } from 'src/app/shared/components/user-list-components/services/user-list.service';
import { DialogService } from '../../core/services/dialog/dialog.service';
import { DialogType } from '../../shared/components/dialog/models/dialogType';
import { SnackbarService } from '../../core/services/snackbar/snackbar.service';
import { ProjectUser, User } from 'src/app/shared/components/user-list-components/models/user';
import { HeaderTemplateComponent } from '../../shared/components/header/header-template.component';
import { DialogProps } from '../../shared/components/dialog/models/dialogProps';
import { AddUserDialogComponent } from './components/add-user-dialog/add-user-dialog.component';
import { UserRoles } from './model/user-roles';
import { TenantUsersStore } from '../../shared/stores/tenant-user-list/tenantUsers.store';
import { UserTenantListService } from '../../shared/services/user-tenant-list/user-tenant-list.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { DialogContentService } from 'src/app/shared/components/dialog/services/dialog-content.service';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';

@Component({
  selector: 'app-project-users',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FunctionBarComponent,
    ContentWrapperComponent,
    ListGridToggleComponent,
    SelectMenuComponent,
    UsersListComponent,
    HeaderTemplateComponent,
    AddUserDialogComponent,
  ],
  templateUrl: './project-users.component.html',
  styleUrl: './project-users.component.scss',
})
export class ProjectUsersComponent implements OnInit, OnDestroy {
  @ViewChild('addUserDialogContent') addUserDialogContent?: TemplateRef<any>;
  @ViewChild('userDialogRef') userDialogRef!: AddUserDialogComponent;

  private readonly destroy$: Subject<void> = new Subject<void>();
  readonly alphabeticalOptions: Option<
    AlphabeticalOrderId.AZ | AlphabeticalOrderId.ZA
  >[] = [
    { id: AlphabeticalOrderId.AZ, label: AlphabeticalOrderLabel.AZ },
    { id: AlphabeticalOrderId.ZA, label: AlphabeticalOrderLabel.ZA },
  ];
  EmptySectionScope = EmptySectionScope;

  projectId: string = '';
  projectUsersView: View = 'list';
  projectUsersData$: Observable<ProjectUsersState>;

  selectedAlphabetOption = signal(this.alphabeticalOptions[0]);
  selectedAlphabetOption$ = toObservable(this.selectedAlphabetOption);
  searchFilterControl: FormControl = new FormControl('');
  searchFilter$: Observable<string> =
    this.searchFilterControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
    );
  filteredProjectUserList$: Observable<ProjectUser[]> = combineLatest([
    this.projectUsersStore.projectUsers$,
    this.searchFilter$,
    this.selectedAlphabetOption$,
  ]).pipe(map(this.userListService.projectUserFilterMap));

  tenantUsers$ = this.tenantUserStore.tenantUsers$;
  existingProjectUserList: ProjectUser[] = [];

  constructor(
    private projectUsersStore: ProjectUsersStore,
    private projectUsersService: ProjectUsersService,
    private projectUsersApiService: ProjectUsersApiService,
    private userListService: UserListService,
    private userTenantListService: UserTenantListService,
    private dialogService: DialogService,
    private dialogContentService: DialogContentService,
    private tenantUserStore: TenantUsersStore,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
  ) {
    const urlPieces = window.location.href.split('/');
    this.projectId = urlPieces[urlPieces.length - 2];

    this.projectUsersData$ = this.projectUsersService.combineProjectUsersData$(
      this.filteredProjectUserList$,
      this.projectUsersStore.isLoading$,
      this.projectUsersStore.hasError$,
    );
  }

  ngOnInit(): void {
    this.getProjectUsers();
    this.projectUsersStore.state$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.existingProjectUserList = value.projectUsers);
  }

  getProjectUsers(): void {
    this.projectUsersApiService
      .getProjectUsers(this.projectId)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  alphabetFilterSelect(
    $event: Option<AlphabeticalOrderId.AZ | AlphabeticalOrderId.ZA>,
  ) {
    this.selectedAlphabetOption.set($event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  removeUserFromProject(projectId: string, user: ProjectUser | User) {
    const config = {
      type: DialogType.CONFIRM,
      title: this.translate.instant('UserList.Dialog.Title'),
      message: `${this.translate.instant('UserList.Dialog.Message')} ${
        user.firstName
      } ${user.lastName}?`,
      secondaryMessage: this.translate.instant('UserList.Dialog.Secondary'),
      confirmText: this.translate.instant('UserList.Dialog.Confirm'),
      delete: true,
      height: '500px',
    };

    const dialogRef = this.dialogService.openDialog(config);

    dialogRef.subscribe(async (response) => {
      if (!response) return;

      try {
        await this.projectUsersApiService.removeUser(projectId, user.userid);
        this.snackbarService.notifySuccess(
          this.translate.instant('UserList.Dialog.Success'),
        );
      } catch (err) {
        this.snackbarService.notifyError(
          this.translate.instant('UserList.Dialog.Error'),
        );
      }
    });
  }

  openDialog() {
    this.userTenantListService.getTenantUsers();
    const config: DialogProps = {
      disableConfirmButton: true,
      type: DialogType.CONFIRM,
      title: this.translate.instant('UserList.AddUser.Title'),
      confirmText: this.translate.instant('UserList.AddUser.Confirm'),
      width: '500px',
    };

    const dialogRef: MatDialogRef<DialogComponent, any> = this.dialogService.openDialogRef(
      config,
      this.addUserDialogContent,
    );

    dialogRef.afterClosed().subscribe(async (closingResult) => {
      const usersToBeAdded: string[] = this.userDialogRef?.selectedEmails || [];
      const userRole =
        this.userDialogRef?.selectUserRole?.value || UserRoles.readonly;

      if (closingResult) {
        try {
          await this.projectUsersApiService.addUsersToProject(
            this.projectId,
            userRole,
            usersToBeAdded
          );
          this.snackbarService.notifySuccess(
            this.translate.instant('UserList.AddUser.Success'),
          );
        } catch (err) {
          this.snackbarService.notifyError(
            this.translate.instant('UserList.AddUser.Error'),
          );
        }
      } else {
        this.dialogContentService.makeDialogContentValid(false);
      }
    });
  }
}
