import { DeviceType } from '../deviceType';
import { DeviceInstanceStatus } from '../deviceInstanceStatus';
import { DeviceConnectionStatus } from '../deviceConnectionStatus';
import { HdkEnvironment } from '../../../../../core/models/interfaces/hdkEnvironment';
import { DeviceFwStatus } from '../deviceFwStatus';
import { DevicePartition, PartitionReporting } from './partitions';
import { computeDeviceLastUse } from 'src/app/features/device-list/utils/helpers';

interface ILastFwUpdate {
  status: DeviceFwStatus;
  targetVersion: string;
  errorMessage: string;
  lastStatusUpdatedAt: string;
}

interface StateChangeDate {
  createdAt: string
  finishedCreating: string,
  startedDevice: string,
  stoppedDevice: string,
}

export interface IDataDevice {
  deviceId: string;
  deviceName: string;
  instanceType: DeviceType;
  creationDate: string;
  scheduledDeletionDate: string;
  loggedStateChange: StateChangeDate | undefined;
  createdBy: string;
  disconnectReason: string;
  instanceStatus: DeviceInstanceStatus;
  instanceState: string;
  instanceConnStatus: DeviceConnectionStatus;
  fwVersion?: string;
  fwUpdate?: boolean;
  lastFwUpdate?: ILastFwUpdate;
  numberOfDeployments: number;
  devicePartitions: DevicePartition[];
  devicePartitionsReporting: PartitionReporting;
  devicePartitionsReportingMsg: string;
  cloudDebugMsg?: string;
  ui: DeviceUiInformation;
}

export interface IDevice {
  environments: HdkEnvironment[];
  connectedEnvironments: HdkEnvironment[];
  deviceId: string;
  deviceName: string;
  instanceType: DeviceType;
  creationDate: string;
  scheduledDeletionDate: string,
  lastUsedDate: string;
  createdBy: string;
  instanceStatus: DeviceInstanceStatus;
  instanceConnectionStatus: DeviceConnectionStatus;
  fwVersion?: string;
  fwUpdate?: boolean;
  lastFwUpdate?: ILastFwUpdate;
  numOfDeployments: string | number;
  devicePartitions: DevicePartition[];
  devicePartitionsReporting: 'UP_TO_DATE' | 'NONE_OR_OUTDATED';
  devicePartitionsReportingMsg: string;
  isSelected?: boolean;
  ui: DeviceUiInformation;
}

export interface DeviceUiInformation {
  color: string;
  status: string;
  message?: string;
}

export class Device implements IDevice {
  environments!: HdkEnvironment[];
  connectedEnvironments!: HdkEnvironment[];
  deviceId!: string;
  deviceName!: string;
  instanceType!: DeviceType;
  creationDate!: string;
  scheduledDeletionDate!: string;
  lastUsedDate!: string;
  createdBy!: string;
  instanceStatus!: DeviceInstanceStatus;
  disconnectedReason!: string;
  instanceConnectionStatus!: DeviceConnectionStatus;
  instanceState?: string;
  fwVersion?: string;
  fwUpdate?: boolean;
  lastFwUpdate?: ILastFwUpdate | undefined;
  numOfDeployments!: number;
  devicePartitions!: DevicePartition[];
  devicePartitionsReporting!: PartitionReporting;
  devicePartitionsReportingMsg!: string;
  cloudDebugMsg?: string;
  isSelected?: boolean;
  ui!: DeviceUiInformation;

  public static Factory(device: IDataDevice, env: HdkEnvironment): Device {
    const newDevice = new Device();
    newDevice.environments = [];
    newDevice.connectedEnvironments = [];
    newDevice.deviceId = device.deviceId;
    newDevice.deviceName = device.deviceName;
    newDevice.creationDate = device.creationDate;
    newDevice.scheduledDeletionDate = device.scheduledDeletionDate === 'NA' ? '' : device.scheduledDeletionDate;
    newDevice.createdBy = device.createdBy;
    newDevice.disconnectedReason = device.disconnectReason;
    newDevice.instanceConnectionStatus = device.instanceConnStatus;
    newDevice.instanceState = device.instanceState;
    newDevice.instanceStatus = device.instanceStatus;
    newDevice.instanceType = device.instanceType;
    newDevice.cloudDebugMsg = device.cloudDebugMsg;
    newDevice.numOfDeployments = device.numberOfDeployments;
    newDevice.lastUsedDate = computeDeviceLastUse(device);

    if (newDevice.instanceType === DeviceType.REAL) {
      newDevice.fwUpdate = device.fwUpdate;
      newDevice.fwVersion = device.fwVersion;
      newDevice.lastFwUpdate = device.lastFwUpdate;
    }

    if (
      newDevice.instanceConnectionStatus === DeviceConnectionStatus.CONNECTED &&
      !newDevice.environments.includes(env)
    ) {
      newDevice.environments.push(env);
    }

    if (
      newDevice.instanceType === DeviceType.REAL &&
      !newDevice.environments.includes(env)
    ) {
      newDevice.environments.push(env);
    }

    if (
      newDevice.instanceConnectionStatus === 'CONNECTED' &&
      !newDevice.connectedEnvironments.includes(env)
    ) {
      newDevice.connectedEnvironments.push(env);
    }
    newDevice.devicePartitions = device.devicePartitions;
    newDevice.devicePartitionsReporting = device.devicePartitionsReporting;
    newDevice.devicePartitionsReportingMsg = device.devicePartitionsReportingMsg;
    newDevice.ui = device.ui;

    return newDevice;
  }
}

export interface DeviceErrorDetail {
  status: string;
  message: string;
}

export interface DeviceDetail {
  deviceId: string;
  instanceType: DeviceType;
  creationDate: string;
  scheduledDeletionDate: string;
  lastUsedDate: string,
  instanceStatus?: string;
  instanceConnectionStatus: DeviceConnectionStatus;
  fwVersion?: string;
  fwUpdate?: boolean;
  numOfDeployments: number;
  devicePartitionsReporting: 'UP_TO_DATE' | 'NONE_OR_OUTDATED';
  devicePartitionsReportingMsg: string;
}
