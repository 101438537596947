import {
  DeviceConnectionStatus,
  DeviceStatus,
} from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';

export const DEVICE_SORT_KEYS = {
  ALPHABETICAL: 'SortKeys.Alphabetical',
  DATE: 'SortKeys.Date',
  LAST_USED_DATE: 'DeviceList.FilterKeys.DateOptions.LastUseDate',
  SCHEDULED_DELETION_DATE: 'DeviceList.FilterKeys.DateOptions.DeletionScheduleDate',
} as const;

export type DeviceSortKey =
  | typeof DEVICE_SORT_KEYS.ALPHABETICAL
  | typeof DEVICE_SORT_KEYS.DATE
  | typeof DEVICE_SORT_KEYS.LAST_USED_DATE
  | typeof DEVICE_SORT_KEYS.SCHEDULED_DELETION_DATE;

export const DEVICE_TYPE_KEYS = {
  ALL: 'DeviceList.FilterKeys.Type.All',
  REAL: 'DeviceList.FilterKeys.Type.Real',
  SIMULATED: 'DeviceList.FilterKeys.Type.Simulated',
  VHPC: 'DeviceList.FilterKeys.Type.VHPC',
} as const;

export type DeviceTypeKey =
  | typeof DEVICE_TYPE_KEYS.ALL
  | typeof DEVICE_TYPE_KEYS.REAL
  | typeof DEVICE_TYPE_KEYS.VHPC;

export const DEVICE_STATUS_KEYS = {
  ALL: 'DeviceList.FilterKeys.ConnectionStatus.All',
  STARTING: 'DeviceList.Starting',
  STOPPING: 'DeviceList.Stopping',
  CREATING: 'DeviceList.InstanceStatus.Creating',
  CONNECTING: 'DeviceList.FilterKeys.ConnectionStatus.Connecting',
  DISCONNECTED: 'DeviceList.FilterKeys.ConnectionStatus.Disconnected',
  UNKNOWN_DEVICE: 'DeviceList.FilterKeys.ConnectionStatus.Unknown',
  CONNECTED: 'DeviceList.FilterKeys.ConnectionStatus.Connected',
  FAILED: 'DeviceList.InstanceStatus.Failed',
  DELETING: 'DeviceList.InstanceStatus.Deleting',
} as const;

export type DeviceStatusKey =
  | typeof DEVICE_STATUS_KEYS.ALL
  | typeof DEVICE_STATUS_KEYS.CONNECTED
  | typeof DEVICE_STATUS_KEYS.DISCONNECTED
  | typeof DEVICE_STATUS_KEYS.DELETING
  | typeof DEVICE_STATUS_KEYS.FAILED
  | typeof DEVICE_STATUS_KEYS.CONNECTING
  | typeof DEVICE_STATUS_KEYS.CREATING
  | typeof DEVICE_STATUS_KEYS.STOPPING
  | typeof DEVICE_STATUS_KEYS.STARTING
  | typeof DEVICE_STATUS_KEYS.UNKNOWN_DEVICE;

export const DEVICE_CREATOR_KEYS = {
  ALL: 'DeviceList.FilterKeys.CreatedBy.All',
  ME: 'DeviceList.FilterKeys.CreatedBy.Me',
} as const;

export type DeviceCreatorKey =
  | typeof DEVICE_CREATOR_KEYS.ALL
  | typeof DEVICE_CREATOR_KEYS.ME;

export const filterKeyToDevicePropertyMap = {
  'DeviceList.FilterKeys.Type.VHPC': DeviceType.VHPC,
  'DeviceList.FilterKeys.Type.Real': DeviceType.REAL,
  'DeviceList.FilterKeys.ConnectionStatus.Connected':
    DeviceConnectionStatus.CONNECTED,
  'DeviceList.FilterKeys.ConnectionStatus.Disconnected':
    DeviceConnectionStatus.DISCONNECTED,
  'DeviceList.Starting': DeviceStatus.STARTING,
  'DeviceList.Stopping': DeviceStatus.STOPPING,
  'DeviceList.InstanceStatus.Creating': DeviceStatus.CREATING,
  'DeviceList.FilterKeys.ConnectionStatus.CONNECTED': DeviceStatus.CONNECTING,
  'DeviceList.FilterKeys.ConnectionStatus.DISCONNECTED':
    DeviceStatus.DISCONNECTED,
  'DeviceList.FilterKeys.ConnectionStatus.CONNECTING': DeviceStatus.CONNECTING,

  'DeviceList.InstanceStatus.CREATE_IN_PROGRESS': DeviceStatus.CREATING,
  'DeviceList.InstanceStatus.CREATION_SUCCESSFUL': DeviceStatus.CONNECTED,
  'DeviceList.InstanceStatus.DELETE_FAILED': DeviceStatus.FAILED,
  'DeviceList.InstanceStatus.CREATE_FAILED': DeviceStatus.FAILED,
  'DeviceList.InstanceStatus.DELETE_IN_PROGRESS': DeviceStatus.DELETING,
};
